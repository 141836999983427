import { Union } from "../fable-library-js.4.20.0/Types.js";
import { union_type, class_type } from "../fable-library-js.4.20.0/Reflection.js";

export class Notification$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OnNext", "OnError", "OnCompleted"];
    }
}

export function Notification$1_$reflection(gen0) {
    return union_type("FSharp.Control.Notification`1", [gen0], Notification$1, () => [[["Item", gen0]], [["Item", class_type("System.Exception")]], []]);
}

