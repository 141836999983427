import { transformAsync } from "./Transform.fs.js";
import { singleton } from "../fable-library-js.4.20.0/AsyncBuilder.js";
import { value } from "../fable-library-js.4.20.0/Option.js";
import { AsyncModule_empty } from "./Core.fs.js";
import { AsyncObserver$1_Create_Z489C0990, AsyncObserver$1_$ctor_Z489C0990, AsyncObserver_autoDetachObserver } from "./AsyncObserver.fs.js";
import { post, receive, start } from "../fable-library-js.4.20.0/MailboxProcessor.js";
import { equals } from "../fable-library-js.4.20.0/Util.js";
import { Notification$1 } from "./Types.fs.js";
import { AsyncDisposable_Composite_2A996D8E } from "./AsyncDisposable.fs.js";

/**
 * Applies the given async function to each element of the stream and returns the stream comprised of the results
 * for each element where the function returns Some with some value.
 */
export function chooseAsync(chooser) {
    return (source) => transformAsync((next, a) => singleton.Delay(() => singleton.Bind(chooser(a), (_arg) => {
        if (_arg == null) {
            return singleton.Return(undefined);
        }
        else {
            const b = value(_arg);
            return singleton.ReturnFrom(next(b));
        }
    })), source);
}

/**
 * Applies the given function to each element of the stream and returns the stream comprised of the results for
 * each element where the function returns Some with some value.
 */
export function choose(chooser) {
    return (source) => transformAsync((next, a) => {
        const matchValue = chooser(a);
        if (matchValue == null) {
            return AsyncModule_empty;
        }
        else {
            return next(value(matchValue));
        }
    }, source);
}

/**
 * Filters the elements of an observable sequence based on an async predicate. Returns an observable sequence that
 * contains elements from the input sequence that satisfy the condition.
 */
export function filterAsync(predicate) {
    return (source) => transformAsync((next, a) => singleton.Delay(() => singleton.Bind(predicate(a), (_arg) => (_arg ? singleton.ReturnFrom(next(a)) : singleton.Return(undefined)))), source);
}

/**
 * Filters the elements of an observable sequence based on a predicate. Returns an observable sequence that
 * contains elements from the input sequence that satisfy the condition.
 */
export function filter(predicate) {
    return (source) => transformAsync((next, a) => {
        if (predicate(a)) {
            return next(a);
        }
        else {
            return AsyncModule_empty;
        }
    }, source);
}

/**
 * Return an observable sequence only containing the distinct contiguous elementsfrom the source sequence.
 */
export function distinctUntilChanged(source) {
    return {
        SubscribeAsync(o) {
            const patternInput = AsyncObserver_autoDetachObserver(o);
            const safeObv = patternInput[0];
            const agent = start((inbox) => {
                const messageLoop = (latest) => singleton.Delay(() => singleton.Bind(receive(inbox), (_arg) => {
                    const n = _arg;
                    return singleton.Bind(singleton.Delay(() => singleton.Combine((n.tag === 1) ? singleton.Bind(safeObv.OnErrorAsync(n.fields[0]), () => singleton.Return(undefined)) : ((n.tag === 2) ? singleton.Bind(safeObv.OnCompletedAsync(), () => singleton.Return(undefined)) : (!equals(n, latest) ? singleton.TryWith(singleton.Delay(() => singleton.Bind(safeObv.OnNextAsync(n.fields[0]), () => singleton.Return(undefined))), (_arg_2) => singleton.Bind(safeObv.OnErrorAsync(_arg_2), () => singleton.Return(undefined))) : singleton.Zero())), singleton.Delay(() => singleton.Return(n)))), (_arg_6) => singleton.ReturnFrom(messageLoop(_arg_6)));
                }));
                return messageLoop(new Notification$1(2, []));
            });
            return singleton.Delay(() => {
                let arg;
                return singleton.ReturnFrom(patternInput[1]((arg = AsyncObserver$1_$ctor_Z489C0990((n_1) => singleton.Delay(() => {
                    post(agent, n_1);
                    return singleton.Zero();
                })), source.SubscribeAsync(arg))));
            });
        },
    };
}

/**
 * Bypasses a specified number of elements in an observable sequence and then returns the remaining elements.
 */
export function skip(count, source) {
    return {
        SubscribeAsync(o) {
            const patternInput = AsyncObserver_autoDetachObserver(o);
            const safeObv = patternInput[0];
            return singleton.Delay(() => {
                let remaining = count;
                return singleton.ReturnFrom(patternInput[1](source.SubscribeAsync(AsyncObserver$1_Create_Z489C0990((n) => singleton.Delay(() => {
                    switch (n.tag) {
                        case 1:
                            return singleton.Bind(safeObv.OnErrorAsync(n.fields[0]), () => singleton.Return(undefined));
                        case 2:
                            return singleton.Bind(safeObv.OnCompletedAsync(), () => singleton.Return(undefined));
                        default:
                            if (remaining <= 0) {
                                return singleton.Bind(safeObv.OnNextAsync(n.fields[0]), () => singleton.Return(undefined));
                            }
                            else {
                                remaining = ((remaining - 1) | 0);
                                return singleton.Zero();
                            }
                    }
                })))));
            });
        },
    };
}

/**
 * Returns a specified number of contiguous elements from the start of an observable sequence.
 */
export function take(count, source) {
    return {
        SubscribeAsync(o) {
            const patternInput = AsyncObserver_autoDetachObserver(o);
            const safeObv = patternInput[0];
            return singleton.Delay(() => {
                let remaining = count;
                return singleton.ReturnFrom(patternInput[1](source.SubscribeAsync(AsyncObserver$1_Create_Z489C0990((n) => {
                    const remaining_1 = remaining | 0;
                    switch (n.tag) {
                        case 1:
                            return safeObv.OnErrorAsync(n.fields[0]);
                        case 2:
                            return safeObv.OnCompletedAsync();
                        default:
                            if (remaining_1 > 1) {
                                remaining = ((remaining_1 - 1) | 0);
                                return safeObv.OnNextAsync(n.fields[0]);
                            }
                            else if (remaining_1 === 1) {
                                return singleton.Delay(() => {
                                    remaining = 0;
                                    return singleton.Bind(safeObv.OnNextAsync(n.fields[0]), () => singleton.Bind(safeObv.OnCompletedAsync(), () => singleton.Return(undefined)));
                                });
                            }
                            else {
                                return AsyncModule_empty;
                            }
                    }
                }))));
            });
        },
    };
}

/**
 * Returns a specified number of contiguous elements from the end of an observable sequence.
 */
export function takeLast(count, source) {
    return {
        SubscribeAsync(o) {
            const patternInput = AsyncObserver_autoDetachObserver(o);
            const safeObv = patternInput[0];
            const queue = [];
            return singleton.Delay(() => singleton.ReturnFrom(patternInput[1](source.SubscribeAsync(AsyncObserver$1_Create_Z489C0990((n) => singleton.Delay(() => {
                switch (n.tag) {
                    case 1:
                        return singleton.Bind(safeObv.OnErrorAsync(n.fields[0]), () => singleton.Return(undefined));
                    case 2:
                        return singleton.Combine(singleton.For(queue, (_arg_1) => singleton.Bind(safeObv.OnNextAsync(_arg_1), () => singleton.Return(undefined))), singleton.Delay(() => singleton.Bind(safeObv.OnCompletedAsync(), () => singleton.Return(undefined))));
                    default: {
                        void (queue.push(n.fields[0]));
                        if (queue.length > count) {
                            queue.splice(0, 1);
                            return singleton.Zero();
                        }
                        else {
                            return singleton.Zero();
                        }
                    }
                }
            }))))));
        },
    };
}

/**
 * Returns the values from the source observable sequence until the other observable sequence produces a value.
 */
export function takeUntil(other, source) {
    return {
        SubscribeAsync(o) {
            const patternInput = AsyncObserver_autoDetachObserver(o);
            const safeObv = patternInput[0];
            return singleton.Delay(() => {
                let arg;
                return singleton.Bind((arg = AsyncObserver$1_$ctor_Z489C0990((n) => {
                    switch (n.tag) {
                        case 1:
                            return safeObv.OnErrorAsync(n.fields[0]);
                        case 2:
                            return AsyncModule_empty;
                        default:
                            return safeObv.OnCompletedAsync();
                    }
                }), other.SubscribeAsync(arg)), (_arg) => singleton.Bind(patternInput[1](source.SubscribeAsync(safeObv)), (_arg_1) => singleton.Return(AsyncDisposable_Composite_2A996D8E([_arg_1, _arg]))));
            });
        },
    };
}

