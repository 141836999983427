import { ignore } from "../fable-library-js.4.20.0/Async.js";
import { FSharp_Control_IAsyncObserver$1__IAsyncObserver$1_ToObserver, Observer$1_$ctor_Z6E901744, AsyncObserver$1_$ctor_Z489C0990 } from "./AsyncObserver.fs.js";
import { Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF } from "./Core.fs.js";
import { zipSeq, withLatestFrom, mergeInner, combineLatest, concatSeq } from "./Combine.fs.js";
import { singleton } from "../fable-library-js.4.20.0/AsyncBuilder.js";
import { AsyncDisposable_get_Empty, System_IDisposable__IDisposable_ToAsyncDisposable } from "./AsyncDisposable.fs.js";
import { min, max, reduceAsync, scanAsync, scanInitAsync, groupBy } from "./Aggregate.fs.js";
import { timer, single, ofAsync, interval, ofAsyncWorker, defer, create, ofSeq } from "./Create.fs.js";
import { takeUntil, takeLast, take, filterAsync, filter, skip, distinctUntilChanged, chooseAsync, choose } from "./Filter.fs.js";
import { sample, delay, debounce } from "./Timeshift.fs.js";
import { toObservable, share, switchLatest, mapiAsync, mapi, mapAsync, map, concatMap, flatMapLatestAsync, flatMapLatest, flatMapiAsync, flatMapi, flatMapAsync, flatMap, retry, catch$ } from "./Transform.fs.js";
import { singleSubject, mbSubject, subject } from "./Subject.fs.js";
import { tapOnNext, tapOnNextAsync, tapAsync } from "./Tap.fs.js";

/**
 * Repeat each element of the sequence n times Subscribes the async observer to the async observable, ignores
 * the disposable
 */
export function FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_RunAsync_7EE9C64A(this$, obv) {
    return ignore(this$.SubscribeAsync(obv));
}

/**
 * Subscribes the observer function (`Notification{'a} -> Async{unit}`) to the AsyncObservable, ignores the
 * disposable.
 */
export function FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_RunAsync_307B9986(this$, obv) {
    return ignore(this$.SubscribeAsync(AsyncObserver$1_$ctor_Z489C0990(obv)));
}

export function FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_Run_7EE9C64A(this$, obv) {
    Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF(FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_RunAsync_7EE9C64A(this$, obv));
}

/**
 * Subscribes the async observer function (`Notification{'a} -> Async{unit}`) to the AsyncObservable
 */
export function FSharp_Control_IAsyncObservable$1__IAsyncObservable$1_SubscribeAsync_307B9986(this$, obv) {
    return this$.SubscribeAsync(AsyncObserver$1_$ctor_Z489C0990(obv));
}

/**
 * Returns an observable sequence that contains the elements of the given sequences concatenated together.
 */
export function AsyncObservable_op_PlusPlus(source, other) {
    return concatSeq([source, other]);
}

/**
 * Subscribes a dispatch function taking notifications.
 */
export function System_IObservable$1__IObservable$1_Subscribe_6BCC300A(this$, dispatch) {
    return this$.Subscribe(Observer$1_$ctor_Z6E901744(dispatch));
}

/**
 * Convert observable (IObservable) to async observable (IAsyncObservable).
 */
export function System_IObservable$1__IObservable$1_ToAsyncObservable(this$) {
    return {
        SubscribeAsync(aobv) {
            return singleton.Delay(() => {
                const obv = FSharp_Control_IAsyncObserver$1__IAsyncObserver$1_ToObserver(aobv);
                const disposable = this$.Subscribe(obv);
                return singleton.Return(System_IDisposable__IDisposable_ToAsyncDisposable(disposable));
            });
        },
    };
}

/**
 * Groups the elements of an observable sequence according to a specified key mapper function. Returns a sequence
 * of observable groups, each of which corresponds to a given key.
 */
export function AsyncRx_groupBy(keyMapper, source) {
    return groupBy(keyMapper, source);
}

/**
 * Applies an accumulator function over an observable sequence and returns each intermediate result. The seed value
 * is used as the initial accumulator value. Returns an observable sequence containing the accumulated values.
 */
export function AsyncRx_scanInit(initial, accumulator, source) {
    return scanInitAsync(initial, (s, x) => singleton.Delay(() => singleton.Return(accumulator(s, x))), source);
}

/**
 * Applies an async accumulator function over an observable sequence and returns each intermediate result. The seed
 * value is used as the initial accumulator value. Returns an observable sequence containing the accumulated
 * values.
 */
export function AsyncRx_scanInitAsync(initial, accumulator, source) {
    return scanInitAsync(initial, accumulator, source);
}

/**
 * Applies an async accumulator function over an observable sequence and returns each intermediate result. The
 * first value is used as the initial accumulator value. Returns an observable sequence containing the accumulated
 * values.
 */
export function AsyncRx_scan(accumulator, source) {
    return scanAsync((s, x) => singleton.Delay(() => singleton.Return(accumulator(s, x))), source);
}

/**
 * Applies an async accumulator function over an observable sequence and returns each intermediate result. The
 * first value is used as the initial accumulator value. Returns an observable sequence containing the accumulated
 * values.
 */
export function AsyncRx_scanAsync(accumulator, source) {
    return scanAsync(accumulator, source);
}

export function AsyncRx_reduceAsync(accumulator, source) {
    return reduceAsync(accumulator)(source);
}

export function AsyncRx_max(source) {
    return max()(source);
}

export function AsyncRx_min(source) {
    return min()(source);
}

/**
 * Merges the specified observable sequences into one observable sequence by combining elements of the sources into
 * tuples. Returns an observable sequence containing the combined results.
 */
export function AsyncRx_combineLatest(other, source) {
    return combineLatest(other, source);
}

/**
 * Concatenates an observable sequence with another observable sequence.
 */
export function AsyncRx_concat(other, source) {
    return concatSeq([source, other]);
}

/**
 * Returns an observable sequence that contains the elements of each given sequences, in sequential order.
 */
export function AsyncRx_concatSeq(sources) {
    return concatSeq(sources);
}

/**
 * Merges an observable sequence of observable sequences into an observable sequence.
 */
export function AsyncRx_mergeInner(source) {
    return mergeInner(0, source);
}

/**
 * Merges an observable sequence with another observable sequence.
 */
export function AsyncRx_merge(other, source) {
    return AsyncRx_mergeInner(ofSeq([source, other]));
}

/**
 * Merges a sequence of observable sequences.
 */
export function AsyncRx_mergeSeq(sources) {
    return AsyncRx_mergeInner(ofSeq(sources));
}

/**
 * Prepends a sequence of values to an observable sequence. Returns the source sequence prepended with the
 * specified values.
 */
export function AsyncRx_startWith(items, source) {
    return concatSeq([ofSeq(items), source]);
}

/**
 * Merges the specified observable sequences into one observable sequence by combining the values into tuples only
 * when the first observable sequence produces an element. Returns the combined observable sequence.
 */
export function AsyncRx_withLatestFrom(other, source) {
    return withLatestFrom(other, source);
}

/**
 * Zip given sequence with source. Combines one and one item from each stream into one tuple.
 */
export function AsyncRx_zipSeq(sequence, source) {
    return zipSeq(sequence, source);
}

/**
 * Creates an async observable (`AsyncObservable{'a}`) from the
 * given subscribe function.
 */
export function AsyncRx_create(subscribe) {
    return create(subscribe);
}

export function AsyncRx_defer(factory) {
    return defer(factory);
}

/**
 * Returns an observable sequence with no elements.
 */
export function AsyncRx_empty() {
    return {
        SubscribeAsync(o) {
            return singleton.Delay(() => singleton.Bind(o.OnCompletedAsync(), () => singleton.Return(AsyncDisposable_get_Empty())));
        },
    };
}

/**
 * Returns an empty observable sequence that never completes.
 */
export function AsyncRx_never() {
    return {
        SubscribeAsync(o) {
            return singleton.Delay(() => singleton.Return(AsyncDisposable_get_Empty()));
        },
    };
}

/**
 * Returns the observable sequence that terminates exceptionally with the specified exception.
 */
export function AsyncRx_fail(error) {
    return ofAsyncWorker((obv, _arg) => obv.OnErrorAsync(error));
}

/**
 * Returns an observable sequence that triggers the increasing sequence starting with 0 after msecs and then
 * repeats with the given period.
 */
export function AsyncRx_interval(msecs, period) {
    return interval(msecs, period);
}

/**
 * Returns the async observable sequence whose single element is the result of the given async workflow.
 */
export function AsyncRx_ofAsync(workflow) {
    return ofAsync(workflow);
}

export function AsyncRx_ofAsyncWorker(worker) {
    return ofAsyncWorker(worker);
}

/**
 * Returns the async observable sequence whose elements are pulled from the given enumerable sequence.
 */
export function AsyncRx_ofSeq(xs) {
    return ofSeq(xs);
}

/**
 * Returns an observable sequence containing the single specified element.
 */
export function AsyncRx_single(x) {
    return single(x);
}

/**
 * Returns an observable sequence that triggers the value 0 after the given duetime.
 */
export function AsyncRx_timer(dueTime) {
    return timer(dueTime);
}

/**
 * Applies the given function to each element of the stream and returns the stream comprised of the results for
 * each element where the function returns Some with some value.
 */
export function AsyncRx_choose(chooser, source) {
    return choose(chooser)(source);
}

/**
 * Applies the given async function to each element of the stream and returns the stream comprised of the results
 * for each element where the function returns Some with some value.
 */
export function AsyncRx_chooseAsync(chooser, source) {
    return chooseAsync(chooser)(source);
}

/**
 * Return an observable sequence only containing the distinct contiguous elementsfrom the source sequence.
 */
export function AsyncRx_distinctUntilChanged(source) {
    return distinctUntilChanged(source);
}

/**
 * Bypasses a specified number of elements in an observable sequence and then returns the remaining elements.
 */
export function AsyncRx_skip(count, source) {
    return skip(count, source);
}

/**
 * Filters the elements of an observable sequence based on a predicate. Returns an observable sequence that
 * contains elements from the input sequence that satisfy the condition.
 */
export function AsyncRx_filter(predicate, source) {
    return filter(predicate)(source);
}

/**
 * Filters the elements of an observable sequence based on an async predicate. Returns an observable sequence that
 * contains elements from the input sequence that satisfy the condition.
 */
export function AsyncRx_filterAsync(predicate, source) {
    return filterAsync(predicate)(source);
}

/**
 * Returns a specified number of contiguous elements from the start of an observable sequence.
 */
export function AsyncRx_take(count, source) {
    return take(count, source);
}

/**
 * Returns a specified number of contiguous elements from the end of an observable sequence.
 */
export function AsyncRx_takeLast(count, source) {
    return takeLast(count, source);
}

/**
 * Returns the values from the source observable sequence until the other observable sequence produces a value.
 */
export function AsyncRx_takeUntil(other, source) {
    return takeUntil(other, source);
}

/**
 * Ignores values from an observable sequence which are followed by
 * another value before the given timeout.
 */
export function AsyncRx_debounce(msecs, source) {
    return debounce(msecs, source);
}

/**
 * Time shifts the observable sequence by the given timeout. The
 * relative time intervals between the values are preserved.
 */
export function AsyncRx_delay(msecs, source) {
    return delay(msecs, source);
}

/**
 * Samples the observable sequence at each interval.
 */
export function AsyncRx_sample(msecs, source) {
    return sample(msecs, source);
}

/**
 * Returns an observable sequence containing the first sequence's elements, followed by the elements of the handler
 * sequence in case an exception occurred.
 */
export function AsyncRx_catch(handler, source) {
    return catch$(handler, source);
}

/**
 * Retries the given Observable retryCount number of times.
 */
export function AsyncRx_retry(retryCount, source) {
    return retry(retryCount, source);
}

/**
 * Projects each element of an observable sequence into an observable sequence and merges the resulting observable
 * sequences back into one observable sequence.
 */
export function AsyncRx_flatMap(mapper, source) {
    return flatMap(mapper)(source);
}

/**
 * Asynchronously projects each element of an observable sequence into an observable sequence and merges the
 * resulting observable sequences back into one observable sequence.
 */
export function AsyncRx_flatMapAsync(mapperAsync, source) {
    return flatMapAsync(mapperAsync)(source);
}

/**
 * Projects each element of an observable sequence into an observable sequence by incorporating the element's index
 * on each element of the source. Merges the resulting observable sequences back into one observable sequence.
 */
export function AsyncRx_flatMapi(mapper, source) {
    return flatMapi(mapper)(source);
}

/**
 * Asynchronously projects each element of an observable sequence into an observable sequence by incorporating the
 * element's index on each element of the source. Merges the resulting observable sequences back into one
 * observable sequence.
 */
export function AsyncRx_flatMapiAsync(mapperAsync, source) {
    return flatMapiAsync(mapperAsync)(source);
}

/**
 * Transforms the items emitted by an source sequence into observable streams, and mirror those items emitted by
 * the most-recently transformed observable sequence.
 */
export function AsyncRx_flatMapLatest(mapper, source) {
    return flatMapLatest(mapper)(source);
}

/**
 * Asynchronosly transforms the items emitted by an source sequence into observable streams, and mirror those items
 * emitted by the most-recently transformed observable sequence.
 */
export function AsyncRx_flatMapLatestAsync(mapperAsync, source) {
    return flatMapLatestAsync(mapperAsync)(source);
}

export function AsyncRx_concatMap(mapper, source) {
    return concatMap(mapper)(source);
}

/**
 * Returns an observable sequence whose elements are the result of invoking the mapper function on each element of
 * the source.
 */
export function AsyncRx_map(mapper, source) {
    return map(mapper)(source);
}

/**
 * Returns an observable sequence whose elements are the result of invoking the async mapper function on each
 * element of the source.
 */
export function AsyncRx_mapAsync(mapperAsync, source) {
    return mapAsync(mapperAsync)(source);
}

/**
 * Returns an observable sequence whose elements are the result of invoking the mapper function and incorporating
 * the element's index on each element of the source.
 */
export function AsyncRx_mapi(mapper, source) {
    return mapi(mapper)(source);
}

/**
 * Returns an observable sequence whose elements are the result of invoking the async mapper function by
 * incorporating the element's index on each element of the source.
 */
export function AsyncRx_mapiAsync(mapper, source) {
    return mapiAsync(mapper)(source);
}

/**
 * Transforms an observable sequence of observable sequences into an observable sequence producing values only from
 * the most recent observable sequence.
 */
export function AsyncRx_switchLatest(source) {
    return switchLatest(source);
}

/**
 * Share a single subscription among multple observers. Returns a new Observable that multicasts (shares) the
 * original Observable. As long as there is at least one Subscriber this Observable will be subscribed and emitting
 * data. When all subscribers have unsubscribed it will unsubscribe from the source Observable.
 */
export function AsyncRx_share(source) {
    return share(source);
}

/**
 * Convert an async observable to an observable.
 */
export function AsyncRx_toObservable(source) {
    return toObservable(source);
}

/**
 * A subject is both an observable sequence as well as an observer. Each notification is broadcasted to all
 * subscribed observers.
 */
export function AsyncRx_subject() {
    return subject();
}

/**
 * A mailbox subject is a subscribable mailbox. Each message is broadcasted to all subscribed observers.
 */
export function AsyncRx_mbSubject() {
    return mbSubject();
}

/**
 * A cold subject that only supports a single subscriber. Will await the caller if no-one is subscribing.
 */
export function AsyncRx_singleSubject() {
    return singleSubject();
}

/**
 * Tap asynchronously into the stream performing side effects by the given async actions.
 */
export function AsyncRx_tapAsync(onNextAsync, onErrorAsync, onCompletedAsync, source) {
    return tapAsync(onNextAsync, onErrorAsync, onCompletedAsync, source);
}

/**
 * Tap asynchronously into the stream performing side effects by the given `onNextAsync` action.
 */
export function AsyncRx_tapOnNextAsync(onNextAsync, source) {
    return tapOnNextAsync(onNextAsync)(source);
}

/**
 * Tap synchronously into the stream performing side effects by the given `onNext` action.
 */
export function AsyncRx_tapOnNext(onNext, source) {
    return tapOnNext(onNext)(source);
}

