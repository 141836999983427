import { class_type } from "../fable-library-js.4.20.0/Reflection.js";
import { defaultOf, curry2, jsOptions } from "../fable-library-js.4.20.0/Util.js";
import { zip, iterate } from "../fable-library-js.4.20.0/Seq.js";
import { defaultArg, map, some, toArray } from "../fable-library-js.4.20.0/Option.js";
import { HookContext } from "./Hook.fs.js";
import { LitElement } from "lit";
import { toArray as toArray_1 } from "../fable-library-js.4.20.0/List.js";
import { Attribute } from "../fable-library-js.4.20.0/Types.js";

function LitElementUtil_isNotNull(x) {
    return !(x == null);
}

function LitElementUtil_isNotReferenceEquals(x, y) {
    return !(x === y);
}

function LitElementUtil_failInit() {
    throw new Error("LitElement.init must be called on top of the render function");
}

function LitElementUtil_failProps(key) {
    throw new Error(`'${key}' field in \`props\` record is not of Prop<'T> type`);
}

export class Prop {
    constructor(defaultValue, options) {
        this.defaultValue = defaultValue;
        this.options = options;
    }
}

export function Prop_$reflection() {
    return class_type("Lit.Prop", undefined, Prop);
}

export function Prop_$ctor_541DA560(defaultValue, options) {
    return new Prop(defaultValue, options);
}

export class Prop$1 extends Prop {
    constructor(defaultValue, options) {
        super(defaultValue, options);
        this.defaultValue_1 = defaultValue;
    }
}

export function Prop$1_$reflection(gen0) {
    return class_type("Lit.Prop`1", [gen0], Prop$1, Prop_$reflection());
}

export function Prop$1_$ctor_4E398B2E(defaultValue, options) {
    return new Prop$1(defaultValue, options);
}

export function Prop__ToConfig(_) {
    return [_.defaultValue, _.options];
}

/**
 * Creates a property accessor.
 */
export function Prop_Of_Z4A92C983(defaultValue, attribute, hasChanged, fromAttribute, toAttribute, reflect) {
    return Prop$1_$ctor_4E398B2E(defaultValue, jsOptions((o) => {
        let matchValue;
        iterate((v) => {
            o.type = v;
        }, toArray((matchValue = defaultValue, (typeof matchValue === "string") ? some(String) : ((typeof matchValue === "number") ? some(Number) : ((typeof matchValue === "number") ? some(Number) : ((typeof matchValue === "boolean") ? some(Boolean) : undefined))))));
        iterate((v_1) => {
            o.reflect = v_1;
        }, toArray(reflect));
        iterate((v_2) => {
            o.hasChanged = v_2;
        }, toArray(map(curry2, hasChanged)));
        iterate((att) => {
            const matchValue_1 = att.trim();
            let matchResult;
            if (matchValue_1 === defaultOf()) {
                matchResult = 0;
            }
            else if (matchValue_1 === "") {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    o.attribute = false;
                    break;
                }
                case 1: {
                    o.attribute = matchValue_1;
                    break;
                }
            }
        }, toArray(attribute));
        let matchResult_1;
        if (fromAttribute != null) {
            matchResult_1 = 0;
        }
        else if (toAttribute != null) {
            matchResult_1 = 0;
        }
        else {
            matchResult_1 = 1;
        }
        switch (matchResult_1) {
            case 0: {
                o.converter = jsOptions((o_1) => {
                    iterate((v_3) => {
                        o_1.fromAttribute = v_3;
                    }, toArray(fromAttribute));
                    iterate((v_4) => {
                        o_1.toAttribute = v_4;
                    }, toArray(toAttribute));
                });
                break;
            }
            case 1: {
                break;
            }
        }
    }));
}

export class LitElementInit$1 {
    constructor() {
        this._initPromise = defaultOf();
        this._useShadowDom = true;
        this._props = defaultOf();
        this._styles = defaultOf();
    }
    get props() {
        const _ = this;
        return _._props;
    }
    set props(v) {
        const _ = this;
        _._props = v;
    }
    get styles() {
        const _ = this;
        return _._styles;
    }
    set styles(v) {
        const _ = this;
        _._styles = v;
    }
    get useShadowDom() {
        const _ = this;
        return _._useShadowDom;
    }
    set useShadowDom(v) {
        const _ = this;
        _._useShadowDom = v;
    }
    init(initFn) {
        const this$ = this;
        this$._initPromise = initFn(this$);
        return defaultOf();
    }
    get hooks() {
        return LitElementUtil_failInit();
    }
}

export function LitElementInit$1_$reflection(gen0) {
    return class_type("Lit.LitElementInit`1", [gen0], LitElementInit$1);
}

export function LitElementInit$1_$ctor() {
    return new LitElementInit$1();
}

export function LitElementInit$1__get_InitPromise(_) {
    return _._initPromise;
}

export class LitHookElement$1 extends LitElement {
    constructor(initProps) {
        super();
        this._hooks = (new HookContext(this));
        initProps(this);
    }
    render() {
        const _ = this;
        return _._hooks.render();
    }
    disconnectedCallback() {
        const _ = this;
        super.disconnectedCallback();
        _._hooks.disconnect();
    }
    init(_arg) {
        const this$ = this;
        return [this$, this$];
    }
    get hooks() {
        const _ = this;
        return _._hooks;
    }
}

export function LitHookElement$1_$reflection(gen0) {
    return class_type("Lit.LitHookElement`1", [gen0], LitHookElement$1, class_type("Lit.LitElement", undefined, LitElement));
}

export function LitHookElement$1_$ctor_61A0B331(initProps) {
    return new LitHookElement$1(initProps);
}

export class LitElementAttribute extends Attribute {
    constructor(name) {
        super();
        this.name = name;
    }
    Decorate(renderFn) {
        const this$ = this;
        const config = LitElementInit$1_$ctor();
        if (renderFn.length > 0) {
            throw new Error("Render function for LitElement cannot take arguments");
        }
        try {
            renderFn.apply(config, []);
        }
        catch (matchValue) {
        }
        if (LitElementInit$1__get_InitPromise(config) == null) {
            LitElementUtil_failInit();
        }
        let pr_1;
        const pr = LitElementInit$1__get_InitPromise(config);
        pr_1 = (pr.then(() => {
            const config_1 = config;
            const styles = LitElementUtil_isNotNull(config_1.styles) ? toArray_1(config_1.styles) : undefined;
            let patternInput_1;
            if (LitElementUtil_isNotNull(config_1.props)) {
                const propsValues = [];
                const propsOptions = {};
                iterate((tupledArg) => {
                    let v_1;
                    const k = tupledArg[0];
                    let patternInput;
                    const matchValue_1 = tupledArg[1];
                    patternInput = ((matchValue_1 instanceof Prop) ? ((v_1 = matchValue_1, Prop__ToConfig(v_1))) : LitElementUtil_failProps(k));
                    const defVal = patternInput[0];
                    propsOptions[k] = patternInput[1];
                    if (!(defVal == null)) {
                        void (propsValues.push([k, defVal]));
                    }
                }, zip(Object.keys(config_1.props), Object.values(config_1.props)));
                patternInput_1 = [some(propsOptions), (this$_1) => {
                    iterate((tupledArg_1) => {
                        this$_1[tupledArg_1[0]] = tupledArg_1[1];
                    }, propsValues);
                }];
            }
            else {
                patternInput_1 = [undefined, (_arg_1) => {
                }];
            }
            const classExpr = class extends LitHookElement$1 {
            constructor() { super(patternInput_1[1]) }
            get renderFn() { return renderFn }
        };
            iterate((props) => {
                Object.defineProperty(classExpr, "properties", { get: (() => props) });
            }, toArray(patternInput_1[0]));
            iterate((styles_1) => {
                Object.defineProperty(classExpr, "styles", { get: (() => styles_1) });
            }, toArray(styles));
            if (!config_1.useShadowDom) {
                classExpr.prototype.createRenderRoot = function() {
                    return this;
                };
            }
            customElements.define(this$.name, classExpr);
        }));
        void (pr_1.catch((er) => {
            console.error(some(er));
        }));
        return () => {
            throw new Error(`${this$.name} is not immediately callable, it must be created in HTML`);
        };
    }
}

export function LitElementAttribute_$reflection() {
    return class_type("Lit.LitElementAttribute", undefined, LitElementAttribute, class_type("Fable.Core.JS.DecoratorAttribute"));
}

export function LitElementAttribute_$ctor_Z721C83C5(name) {
    return new LitElementAttribute(name);
}

/**
 * Creates and Dispatches a Browser `Event`
 */
export function Lit_LitElement__LitElement_dispatchEvent_Z1AA9F00E(this$, name, bubbles, composed, cancelable) {
    let evt, opts, objectArg;
    (evt = ((opts = {
        bubbles: defaultArg(bubbles, true),
        composed: defaultArg(composed, true),
        cancelable: defaultArg(cancelable, true),
    }, new Event(name, opts))), (objectArg = this$.renderRoot, objectArg.dispatchEvent(evt)));
}

/**
 * Creates and Dispatches a Browser `CustomEvent`
 */
export function Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(this$, name, detail, bubbles, composed, cancelable) {
    let evt, opts, objectArg;
    (evt = ((opts = {
        detail: detail,
        bubbles: defaultArg(bubbles, true),
        composed: defaultArg(composed, true),
        cancelable: defaultArg(cancelable, true),
    }, new CustomEvent(name, opts))), (objectArg = this$.renderRoot, objectArg.dispatchEvent(evt)));
}

