import { Notification$1 } from "./Types.fs.js";
import { union_type, class_type } from "../fable-library-js.4.20.0/Reflection.js";
import { Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF } from "./Core.fs.js";
import { singleton } from "../fable-library-js.4.20.0/AsyncBuilder.js";
import { post, receive, start } from "../fable-library-js.4.20.0/MailboxProcessor.js";
import { Union } from "../fable-library-js.4.20.0/Types.js";
import { cons, empty } from "../fable-library-js.4.20.0/List.js";
import { AsyncDisposable_Create_Z56C29B26 } from "./AsyncDisposable.fs.js";

export class AsyncObserver$1 {
    constructor(fn) {
        this.fn = fn;
    }
    OnNextAsync(x) {
        const this$ = this;
        return this$.fn(new Notification$1(0, [x]));
    }
    OnErrorAsync(err) {
        const this$ = this;
        return this$.fn(new Notification$1(1, [err]));
    }
    OnCompletedAsync() {
        const this$ = this;
        return this$.fn(new Notification$1(2, []));
    }
}

export function AsyncObserver$1_$reflection(gen0) {
    return class_type("FSharp.Control.AsyncObserver`1", [gen0], AsyncObserver$1);
}

export function AsyncObserver$1_$ctor_Z489C0990(fn) {
    return new AsyncObserver$1(fn);
}

export function AsyncObserver$1_Create_Z489C0990(fn) {
    return AsyncObserver$1_$ctor_Z489C0990(fn);
}

export class Observer$1 {
    constructor(fn) {
        this.fn = fn;
    }
    OnNext(x) {
        const this$ = this;
        this$.fn(new Notification$1(0, [x]));
    }
    OnError(err) {
        const this$ = this;
        this$.fn(new Notification$1(1, [err]));
    }
    OnCompleted() {
        const this$ = this;
        this$.fn(new Notification$1(2, []));
    }
}

export function Observer$1_$reflection(gen0) {
    return class_type("FSharp.Control.Observer`1", [gen0], Observer$1);
}

export function Observer$1_$ctor_Z6E901744(fn) {
    return new Observer$1(fn);
}

export function Observer$1_Create_Z6E901744(fn) {
    return Observer$1_$ctor_Z6E901744(fn);
}

/**
 * Convert async observer (IAsyncObserver) to an observer (IObserver).
 */
export function FSharp_Control_IAsyncObserver$1__IAsyncObserver$1_ToObserver(this$) {
    return {
        OnNext(x) {
            Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF(this$.OnNextAsync(x));
        },
        OnError(err) {
            Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF(this$.OnErrorAsync(err));
        },
        OnCompleted() {
            Microsoft_FSharp_Control_FSharpAsync__Async_Start$0027_Static_ZF5A23EF(this$.OnCompletedAsync());
        },
    };
}

/**
 * Convert observer (IObserver) to an async observer (IAsyncObserver).
 */
export function System_IObserver$1__IObserver$1_ToAsyncObserver(this$) {
    return {
        OnNextAsync(x) {
            return singleton.Delay(() => {
                this$.OnNext(x);
                return singleton.Zero();
            });
        },
        OnErrorAsync(err) {
            return singleton.Delay(() => {
                this$.OnError(err);
                return singleton.Zero();
            });
        },
        OnCompletedAsync() {
            return singleton.Delay(() => {
                this$.OnCompleted();
                return singleton.Zero();
            });
        },
    };
}

/**
 * Safe observer that wraps the given observer. Makes sure that invocations are serialized and that the Rx grammar
 * (OnNext* (OnError|OnCompleted)?) is not violated.
 */
export function AsyncObserver_safeObserver(obv, disposable) {
    const agent = start((inbox) => {
        const messageLoop = (stopped) => singleton.Delay(() => singleton.Bind(receive(inbox), (_arg) => {
            const n = _arg;
            return singleton.Combine(stopped ? singleton.ReturnFrom(messageLoop(stopped)) : singleton.Zero(), singleton.Delay(() => singleton.Bind(singleton.Delay(() => ((n.tag === 1) ? singleton.Bind(disposable.DisposeAsync(), () => singleton.Bind(obv.OnErrorAsync(n.fields[0]), () => singleton.Return(true))) : ((n.tag === 2) ? singleton.Bind(disposable.DisposeAsync(), () => singleton.Bind(obv.OnCompletedAsync(), () => singleton.Return(true))) : singleton.TryWith(singleton.Delay(() => singleton.Bind(obv.OnNextAsync(n.fields[0]), () => singleton.Return(false))), (_arg_2) => singleton.Bind(obv.OnErrorAsync(_arg_2), () => singleton.Return(true)))))), (_arg_8) => singleton.ReturnFrom(messageLoop(_arg_8)))));
        }));
        return messageLoop(false);
    });
    return {
        OnNextAsync(x_1) {
            return singleton.Delay(() => {
                post(agent, new Notification$1(0, [x_1]));
                return singleton.Zero();
            });
        },
        OnErrorAsync(err) {
            return singleton.Delay(() => {
                post(agent, new Notification$1(1, [err]));
                return singleton.Zero();
            });
        },
        OnCompletedAsync() {
            return singleton.Delay(() => {
                post(agent, new Notification$1(2, []));
                return singleton.Zero();
            });
        },
    };
}

class AsyncObserver_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Disposable", "Dispose"];
    }
}

function AsyncObserver_Msg_$reflection() {
    return union_type("FSharp.Control.AsyncObserver.Msg", [], AsyncObserver_Msg, () => [[["Item", class_type("FSharp.Control.IAsyncRxDisposable")]], []]);
}

export function AsyncObserver_autoDetachObserver(obv) {
    const agent = start((inbox) => {
        const messageLoop = (disposables) => singleton.Delay(() => singleton.Bind(receive(inbox), (_arg) => {
            const cmd = _arg;
            return singleton.Bind(singleton.Delay(() => ((cmd.tag === 1) ? singleton.Combine(singleton.For(disposables, (_arg_1) => singleton.Bind(_arg_1.DisposeAsync(), () => singleton.Return(undefined))), singleton.Delay(() => singleton.Return(empty()))) : singleton.Return(cons(cmd.fields[0], disposables)))), (_arg_3) => singleton.ReturnFrom(messageLoop(_arg_3)));
        }));
        return messageLoop(empty());
    });
    return [AsyncObserver_safeObserver(obv, AsyncDisposable_Create_Z56C29B26(() => singleton.Delay(() => {
        post(agent, new AsyncObserver_Msg(1, []));
        return singleton.Zero();
    }))), (disposable_1) => singleton.Delay(() => singleton.Bind(disposable_1, (_arg_4) => {
        const disp_2 = _arg_4;
        post(agent, new AsyncObserver_Msg(0, [disp_2]));
        return singleton.Return(disp_2);
    }))];
}

